import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

export default function contact() {
  return (
    <Layout>
      <div style={{ margin: '4rem' }}>
        <h1>Coming soon...</h1>
        <Link to="/">
          <button>Go back</button>
        </Link>
      </div>
    </Layout>
  )
}
